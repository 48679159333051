<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18663562247">1-866-356-2247</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a :href="$t('site_hiver_2023.tonik_link')" target="_blank" class="cta red">{{ $t('site.achetez-pass') }}</a>
      </div>
      <a href="tel:18663562247" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a :href="$t('site_hiver_2023.tonik_link')" target="_blank" class="cta red">{{ $t('site.achetez-pass') }}</a>
      </div>
    </header>

    <section class="terms-wrap">
      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Retour</button><br><br>

        <h1 class="big-title">Modalités<br>Passe Tonik</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          <li class="regular-text">La passe Tonik est valable toute la saison 2023-2024 sauf durant quelques périodes spécifiques selon le calendrier suivant : <a href="https://medias.tremblant.ca/pdf/ski/calendrier-passe-tonik-2023-24.pdf" target="_blank">Imprimer le calendrier 2023-2024 (PDF)</a></li>
          <li class="regular-text">Bénéficiez de 3 jours flottants valables à tout moment durant la saison à l’exception du 27 et 30 décembre 2023 et du 17 et 18 février 2024. Les journées flottantes seront débitées automatiquement à la remontée.</li>
          <li class="regular-text">Bénéficiez de 15 % de rabais sur toutes les journées non incluses au calendrier (après épuisement des 3 journées flottantes!). Rabais applicable sur un billet régulier 1-jour, tarification billetterie.</li>
          
          <li class="regular-text">Tous les détenteurs de passe 22-23 verront leur accès prolongé jusqu’au 23 avril.</li>
          <li class="regular-text">Les nouveaux détenteurs de passes de Tonik auront accès au ski de printemps dès le 3 avril cette saison.</li>
        </ul>

        <h2 class="small-title">Achat</h2>
        <ul>
          <li class="regular-text">La passe Tonik peut être achetée en ligne via ikonpass.com ou par téléphone au <a href="tel:18663562247">1-866-356-2247</a>.</li>
          <li class="regular-text">Pour profiter des journées additionnelles à 15%, ajouter l’option Accès direct aux remontées et présentez-vous directement à la remontée. Un paiement sécurisé se fera automatiquement et le rabais correspondant à la journée sera appliqué, selon le cas. Vous pouvez activer l’option ADR en personne au Service à la clientèle ou par téléphone.</li>
          <li class="regular-text">L’âge de référence est celui à la date de l’achat.</li>
          <li class="regular-text">Les journées flottantes seront débitées automatiquement à la remontée.</li>
          <li class="regular-text">Pour accéder au tarif étudiant, les détenteurs d'une passe Tonik étudiante éligibles doivent valider leur statut étudiant à temps plein lors de l'achat en ligne avec Sheer ID.</li>
          <li class="regular-text">La passe Tonik est offerte aux résidents canadiens seulement.</li>
          <li class="regular-text">La passe de randonnée alpine doit être récupérée au Service à la clientèle dès l’automne 2023.</li>
        </ul>

        <h2 class="small-title">Livraison</h2>
        <ul>
          <li class="regular-text">La passe Tonik sera envoyée par la poste à l’automne.</li>
          <li class="regular-text">Les journées flottantes seront ajoutées directement sur votre passe.</li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <ul>
          <li class="regular-text">Les passes de saison valables à Tremblant (Ikon Pass, Ikon Base Pass et Tonik) ne sont remboursables en aucune circonstance à moins de s’être procuré la Protection de l’abonnement Spot au préalable.</li>
          <li class="regular-text">Les journées flottantes doivent être utilisées durant la saison 2023-2024 et ne sont pas valables les 27 et 30 décembre 2023 et du 17 et 18 février 2024. Elles ne sont ni remboursables, ni transférables.</li>
          <li class="regular-text">
            L’Assurance Aventure est offerte gratuitement à l'achat d’une passe Tonik, vous offrant ainsi une protection vous permettant de reporter la valeur de votre passe Tonik inutilisée avant le 14 décembre 2023.
          </li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">La passe Tonik est incessible (ne peut être transférée d'une personne à une autre).</li>
          <li class="regular-text">Limite d'un accès journalier par détenteur, par jour.</li>
          <li class="regular-text">Le rabais de 15 % ne s’applique pas aux billets de remontées 1⁄2 journée.</li>
          <li class="regular-text">Si une situation hors de notre contrôle ne permet pas l’ouverture de la station lors d’une des journées prédéterminées du calendrier de la passe Tonik, cette journée ne sera pas remise à une date ultérieure de la saison. La passe Tonik ne garantit pas l’accès à la station pour un nombre minimum de jours ou que la station va être ouverte pour un nombre minimum de jours.</li>
          <li class="regular-text">La passe Tonik est offerte en exclusivité aux résidents canadiens. Si vous tentez d’acheter cette passe sans être résident canadien, votre passe ne sera pas activée.</li>
        </ul>
        
      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">Tonik pass<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">The Tonik Pass is valid for the 2023/24 ski season except on the following dates : <a href="https://medias.tremblant.ca/pdf/ski/tonik-pass-calendar-2023-24.pdf" target="_blank">Print the 2023–2024 Tonik Calendar (PDF)</a></li>
          <li class="regular-text">Get 3 floating days valid anytime during the season, except on December 27-30, 2023, and on February 17-18, 2024.</li>
          <li class="regular-text">Get 15% discount on all days not included in the calendar (discount applicable on a 1-day regular ticket office ticket)</li>
          
          <li class="regular-text">All 22-23 pass holders will therefore see their access extended until April 23.</li>
          <li class="regular-text">New pass holders will have access to spring skiing as of April 3 this season.</li>
        </ul>

        <h2 class="small-title">Purchase</h2>
        <ul>
          <li class="regular-text">Tonik Pass must be purchased online on ikonpass.com or by phone at <a href="18663562247">1-866-356-2247</a>.</li>
          <li class="regular-text">Age of reference is age on date of purchase.</li>
          <li class="regular-text">Floating days will be automatically scanned and deducted at the lift.</li>
          <li class="regular-text">To take advantage of the 15% discount on additional days, add the Direct-to-lift access to your Tremblant pass and go directly to the lifts. A secure payment with your applicable discount will be automatically applied to your card. You can activate the DTL option at our Guest Service Desk or by phone.</li>
          <li class="regular-text">To unlock their student discount, student Tonik Pass holders can validate their full-time student status online with SheerID. No promotional code is needed.</li>
          <li class="regular-text">The Tonik Pass is exclusive to Canadian residents.</li>
          <li class="regular-text">The Alpine Touring pass must be picked up at our Guest Services Desk at Chalet des Voyageurs in the fall of 2023.</li>
        </ul>

        <h2 class="small-title">Delivery</h2>
        <ul>
          <li class="regular-text">The Tonik Pass will sent by mail in the Fall.</li>
          <li class="regular-text">Floating days will be added directly on your pass.</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <ul>
          <li class="regular-text">Season passes for Tremblant (Ikon Pass, Ikon Base Pass and Tonik Pass) are not refundable under any circumstance without the Spot Pass Protection.</li>
          <li class="regular-text">Floating days must be used during the 2021-2022 season and are not valid on December 27-30, 2023, and on February 17-18, 2024. They are not refundable and cannot be transferred.</li>
          <li class="regular-text">
            Adventure Assurance comes free with Tonik Pass. Pass holders of unused Tonik Pass can defer the value before December 14, 2023.
          </li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Tonik Pass is not transferable (cannot be transferred from one person to the next).</li>
          <li class="regular-text">Limit of one regular lift access per person, per day.</li>
          <li class="regular-text">15% discounts not applicable on half-day tickets.</li>
          <li class="regular-text">If the mountain is not in operation on one of the Tonik calendar days, the day will not be re-attributed to a later day in the season. The pass does not guarantee access to the Resort for a minimum number of days or that the Resort will be open for a minimum number of days.</li>
          <li class="regular-text">The Tonik Pass is offered exclusively to Canadian residents. If you try to purchase this pass and are not a Canadian resident, your pass will not be activated.</li>
        </ul>

      </div>
      
    </section>

    <footer-bar></footer-bar>

    <div class="logo-partenaire">
      <a v-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"></a>
    </div>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: "Éclaté de haut en bas"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        },
        {
          name: 'twitter:image',
          content: ''
        }
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
